import { convertToHsla, getImageData } from './helpers'

type AverageRgb = string

export async function getAverageColor(url: string) {
  const data = await getImageData(url).then(data => data)
  const rgb = getAverageRGB(data)

  return convertToHsla(rgb)
}

function getAverageRGB(data: Uint8ClampedArray): AverageRgb {
  const blockSize = 2 // only visit every * pixels
  let i = -4
  const rgb = { r: 0, g: 0, b: 0 }
  let count = 0

  while ((i += blockSize * 4) < data.length) {
    ++count
    rgb.r += data[i]
    rgb.g += data[i + 1]
    rgb.b += data[i + 2]
  }

  // ~~ used to floor values
  rgb.r = ~~(rgb.r / count)
  rgb.g = ~~(rgb.g / count)
  rgb.b = ~~(rgb.b / count)

  return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`
}
