interface Props {
  numberOfPhotos: number
  handleCancelCheckboxMode: () => void
  handleDeleteSelectedImages: () => void
  handleAddToFrame: () => void
}

export default function SidebarCheckboxNodal({
  numberOfPhotos,
  handleCancelCheckboxMode,
  handleDeleteSelectedImages,
  handleAddToFrame
}: Props) {
  return (
    <div className="sidebar__help">
      <div className="sidebar__help-head">
        <div className="sidebar__help-count">{numberOfPhotos} photos</div>
        <button className="sidebar__help-cancel" onClick={handleCancelCheckboxMode}>
          Cancel
        </button>
      </div>
      <div className="sidebar__help-buttons">
        <button className="button button_light-red" onClick={handleDeleteSelectedImages}>
          Delete
        </button>
        <button className="button button_green" onClick={handleAddToFrame}>
          Add to frame
        </button>
      </div>
    </div>
  )
}
