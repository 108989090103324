/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import { useCallback, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import cls from 'classnames'
import { useDropzone } from 'react-dropzone'
import { addImage as addProductImage, deleteImage as deleteProductImage } from '@models/product'
import { addImage as addSidebarImage } from '@models/sidebar'
import { fileToImage, Image } from '@models/share'
import GridCellPhoto from '@components/GridCellPhoto/GridCellPhoto'
// eslint-disable-next-line import/named
import { NewIndexGetter, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

interface Props {
  index: number
  image: Image
  size: number
  left: number
  top: number
  editorPhotoId: string | null
  onClick: (id?: string) => void
  getNewIndex: NewIndexGetter
}

function GridCell({ index, image, size, left, top, editorPhotoId, onClick, getNewIndex }: Props) {
  const editorRef = useRef<AvatarEditor>(null)

  const [showHover, setShowHover] = useState(false)
  const [showDrop, setShowDrop] = useState(false)

  const { getRootProps, open, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    accept: {
      'image/*': []
    },
    multiple: false,

    onDrop: acceptedFiles => {
      const image = fileToImage(acceptedFiles[0])
      addSidebarImage(image)
      addProductImage({ cell: index, image })
    }
  })

  const handleOnClick = useCallback(
    (e: any) => {
      e.preventDefault()
      if (!image.previewUrl) {
        open()
      } else {
        if (editorPhotoId === image.id) {
          onClick()
        } else {
          onClick(image.id)
        }
      }
    },
    [image.previewUrl, image.id, open, editorPhotoId, onClick]
  )

  const handleDeleteClick = useCallback(() => {
    deleteProductImage(index)
  }, [index])

  const {
    setNodeRef,
    isOver,
    attributes,
    listeners,
    isDragging,
    transform,
    transition: defaultTransition
  } = useSortable({
    id: image.id,
    disabled: !image.previewUrl || editorPhotoId === image.id,
    getNewIndex,
    data: {
      index,
      originalId: image.originalId,
      type: 'photo',
      accepts: ['sidebar', 'photo']
    }
  })

  let transition = defaultTransition
  if (transition) {
    transition += ', background 0.3s linear'
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={cls('grid-photos-b__item', {
        'grid-photos-b__item_blue': isOver || isDragActive,
        'grid-photos-b__item_active': editorPhotoId === image.id
      })}
      // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
      onClick={handleOnClick}
    >
      <div {...getRootProps({ className: 'grid-photos-b__item_dropzone' })}>
        <input {...getInputProps()} />

        {!image.previewUrl ? (
          <>
            <svg className="icon__image" width="27px" height="28px">
              <use xlinkHref="#image"></use>
            </svg>
          </>
        ) : (
          <>
            {editorPhotoId === image.id ? (
              <>
                <AvatarEditor
                  ref={editorRef}
                  image={image.previewUrl!}
                  border={0}
                  height={size}
                  width={size}
                  className="grid-photos-b__pic"
                />
                <div className="grid-photos-b__actions">
                  <div className="grid-photos-b__actions-size">
                    <button className="grid-photos-b__actions-fullscreen">
                      <svg className="icon__fullscreen" width="14px" height="14px">
                        <use xlinkHref="#fullscreen"></use>
                      </svg>
                    </button>
                    <div className="grid-photos-b__actions-value">
                      <button className="grid-photos-b__actions-control">
                        <svg className="icon__minus" width="14px" height="2px">
                          <use xlinkHref="#minus"></use>
                        </svg>
                      </button>
                      <div className="grid-photos-b__actions-line">
                        <span style={{ width: '40%' }}></span>
                      </div>
                      <button className="grid-photos-b__actions-control">
                        <svg className="icon__plus" width="14px" height="14px">
                          <use xlinkHref="#plus"></use>
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="grid-photos-b__actions-buttons">
                    <button className="grid-photos-b__actions-button">
                      <svg className="icon__edit" width="20px" height="20px">
                        <use xlinkHref="#edit"></use>
                      </svg>
                      Edit
                    </button>
                    <button className="grid-photos-b__actions-button grid-photos-b__actions-button_desktop">
                      <svg className="icon__rotate" width="19px" height="18px">
                        <use xlinkHref="#rotate"></use>
                      </svg>
                      Rotate
                    </button>
                    <button className="grid-photos-b__actions-button grid-photos-b__actions-button_mobile">
                      <svg className="icon__replace" width="21px" height="21px">
                        <use xlinkHref="#replace"></use>
                      </svg>
                      Replace
                    </button>
                    <button className="grid-photos-b__actions-button" onClick={handleDeleteClick}>
                      <svg className="icon__delete" width="20px" height="22px">
                        <use xlinkHref="#delete"></use>
                      </svg>
                      Delete
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <GridCellPhoto image={image} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default GridCell
