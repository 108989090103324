import { useEffect, useRef, useState } from 'react'
import { createPreviewRectsWithoutImage, createRoundRect } from '../../utils/product-previews/utils'
import { layoutSizes } from '../../constants/product-previews/layouts/layoutSizes'
import { cardSizes } from '../../constants/product-previews/layouts/cardSizes'
import { defaultOffsets } from '../../constants/product-previews/layouts/defaultOffsets'
import { Orientation, Urls } from '../../types/product-previews/product-preview-props'
import { fillPreviewImages } from '../../utils/product-previews/fillPreviewImages'

const LAYOUT_RADIUS = 20

interface Props {
  urls: Urls
  width: number
  height: number
  orientation?: Orientation
}

function ProductPreview({ urls, width, height, orientation = Orientation.square }: Props) {
  const { layoutWidth, layoutHeight } = layoutSizes[orientation]
  const { cardWidth, cardHeight } = cardSizes[orientation]
  const { x, y, step } = defaultOffsets

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null)

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d')
      if (ctx) {
        canvasRef.current.width = layoutWidth
        canvasRef.current.height = layoutHeight

        const previewSize = {
          width: orientation === 'square' ? Math.round(cardWidth / width - (width - 1)) : cardWidth,
          height: orientation === 'square' ? Math.round(cardHeight / height - (height - 1)) : cardHeight
        }
        const defaultOffset = { x, y }

        createRoundRect(ctx, 0, 0, layoutWidth, layoutHeight, LAYOUT_RADIUS)

        createPreviewRectsWithoutImage(ctx, previewSize, width, height, step, defaultOffset)

        setContext(ctx)
      }
    }
  }, [width, height, cardWidth, cardHeight, context, orientation, step, layoutWidth, layoutHeight, x, y])

  useEffect(() => {
    const defaultOffset = { x, y }

    const previewSize = {
      width: orientation === 'square' ? Math.round(cardWidth / width - (width - 1)) : cardWidth,
      height: orientation === 'square' ? Math.round(cardHeight / height - (height - 1)) : cardHeight
    }

    if (context) {
      fillPreviewImages(context, previewSize, urls, width, height, step, defaultOffset)
    }
  }, [urls, width, height, cardWidth, cardHeight, context, orientation, step, layoutWidth, layoutHeight, x, y])

  return <canvas ref={canvasRef} />
}

export default ProductPreview
