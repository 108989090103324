import cls from 'classnames'
import { $sidebarImageToDelete, deleteSidebarImage, selectSidebarImageToDelete } from '@models/sidebar'
import { useStore } from 'effector-react'
import { useCallback } from 'react'

function PhotoDeleteModal() {
  const imageToDelete = useStore($sidebarImageToDelete)

  const handleConfirmDelete = useCallback(() => {
    if (imageToDelete) {
      deleteSidebarImage(imageToDelete)
    }
  }, [imageToDelete])

  const handleCancelDelete = useCallback(() => {
    selectSidebarImageToDelete(null)
  }, [imageToDelete])

  return (
    <div className={cls('modal-b', { 'modal-b_show': !!imageToDelete })} id="modal-remove">
      <div className="modal-b__block">
        <div className="modal-b__title">Delete photo?</div>
        <div className="modal-b__text">Subheading text in some words.</div>
        <div className="modal-b__buttons">
          <button className="button button_light-blue" onClick={handleCancelDelete}>
            Cancel
          </button>
          <button className="button button_red" onClick={handleConfirmDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}

export default PhotoDeleteModal
