import { createEvent, createStore } from 'effector-logger'
import { Image, uuid } from '../share'

type CellNumber = number

type ProductSizeProps = number

interface AddProductImageProps {
  cell: CellNumber
  image: Image
}

interface AddFileToProductProps {
  cell: CellNumber
  file: File
}

type SwapTwoImagesProps = [CellNumber, CellNumber]

type DeleteImageProps = CellNumber

const defaultSize = 5

export const $productSize = createStore<ProductSizeProps>(defaultSize)
export const setProductSize = createEvent<ProductSizeProps>()

export const defaultProductImages = [...Array(Math.pow(defaultSize, 2))].map((_, i) => {
  const id = uuid()
  return {
    id,
    originalId: id
  }
})

export const $productImages = createStore<Image[]>([...defaultProductImages])

export const addImage = createEvent<AddProductImageProps>()
export const addFile = createEvent<AddFileToProductProps>()
export const swapTwoImages = createEvent<SwapTwoImagesProps>()
export const deleteImage = createEvent<DeleteImageProps>()
