import { useCallback } from 'react'
import cls from 'classnames'
import { setProductSize } from '@models/product'

interface Props {
  children: JSX.Element
  currentSize: number
  isSelectedItem: boolean
}

export function ProductPreviewItem({ children, currentSize, isSelectedItem }: Props) {
  const handleSetSelectedItem = useCallback(() => {
    setProductSize(currentSize)
  }, [currentSize])

  return (
    <div
      className={cls('content__layout-item', {
        'active-layout': isSelectedItem
      })}
      onClick={handleSetSelectedItem}
    >
      <div className="content__layout-block">{children}</div>
    </div>
  )
}

export default ProductPreviewItem
