import { Image } from '@models/share'
import { selectSidebarImage, selectSidebarImageToDelete } from '@models/sidebar'
import { useDraggable } from '@dnd-kit/core'
import { convertToHex, convertToHslaString } from '../../shared/get-average-color/helpers'
import { useCallback, useMemo, SyntheticEvent } from 'react'
import cls from 'classnames'

interface Props {
  image: Image
  isDragging?: boolean
  isCheckboxMode?: boolean
  isActive?: boolean
  countOfUsing?: number
  onOpenPhotoViewModal?: (url: string) => void
  showDebugInfo?: boolean
}

function SidebarPhoto({
  onOpenPhotoViewModal,
  image,
  countOfUsing,
  isDragging = false,
  isCheckboxMode = false,
  isActive = false,
  showDebugInfo = true
}: Props) {
  // const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
  //   type: 'photo',
  //   item: { id: image.previewUrl },
  //   collect: monitor => ({
  //     isDragging: monitor.isDragging()
  //   }),
  //   end: (item, monitor) => {
  //     const didDrop = monitor.didDrop()
  //     console.log({ item, didDrop })
  //   }
  // }))
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: image.id,
    data: {
      type: 'sidebar',
      originalId: image.originalId
    }
  })

  const style = useMemo(
    () => ({
      transform: isDragging ? 'rotate(4deg)' : 'none',
      opacity: isDragging ? '0.9' : undefined
    }),
    [isDragging]
  )

  const handleOpenPhotoViewModal = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      onOpenPhotoViewModal && onOpenPhotoViewModal(image?.previewUrl || '')
    },
    [onOpenPhotoViewModal, image.previewUrl]
  )

  const handleSelectImageToDelete = useCallback(() => {
    selectSidebarImageToDelete(image)
  }, [image])

  const handleSelectImage = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      selectSidebarImage(image)
    },
    [image]
  )

  const attrsAndListeners = useMemo(() => {
    return isCheckboxMode ? {} : { ...listeners, ...attributes }
  }, [isCheckboxMode, attributes, listeners])

  const handleClickCheckbox = useMemo(() => {
    return isCheckboxMode ? handleSelectImage : undefined
  }, [isCheckboxMode, handleSelectImage])

  const styleDebugInfo = useMemo(() => {
    if (image.averageColor) {
      return { background: `${image.averageColor.authored}` }
    }
  }, [image])

  return (
    <div
      ref={setNodeRef}
      className={cls('sidebar__photos-item', { 'checkbox-mode': isCheckboxMode, 'sidebar-active-image': isActive })}
      {...attrsAndListeners}
      onClick={handleClickCheckbox}
      style={style}
    >
      <div className={cls('sidebar-checkbox', { 'checkbox-active': isActive })} onClick={handleSelectImage}>
        <svg width="20px" height="20px">
          <use xlinkHref="#check" />
        </svg>
      </div>
      <div className="sidebar__photos-item_img">
        {!!countOfUsing && (
          <span className="sidebar__photos-group">
            <svg className="icon__group" width="16px" height="16px">
              <use xlinkHref="#group" />
            </svg>
            {countOfUsing}
          </span>
        )}
        {image && image.averageColor && showDebugInfo && (
          <div className="debug-info-wrapper" style={styleDebugInfo}>
            <span className="debug-info-value">{convertToHex(image.averageColor.authored)}</span>
            <span className="debug-info-value">{convertToHslaString(image.averageColor.authored)}</span>
            <span className="debug-info-value">{image.averageColor.authored}</span>
          </div>
        )}
        <img src={image.previewUrl} alt={image.previewUrl} />
      </div>
      {!isDragging && !isCheckboxMode && !showDebugInfo && (
        <div className="sidebar__photos-actions">
          <button onClick={handleOpenPhotoViewModal} className="sidebar__photos-control">
            <svg className="icon__zoomin" width="20px" height="20px">
              <use xlinkHref="#zoomin" />
            </svg>
          </button>
          <button className="sidebar__photos-control" onClick={handleSelectImageToDelete}>
            <svg className="icon__delete" width="20px" height="22px">
              <use xlinkHref="#delete" />
            </svg>
          </button>
        </div>
      )}
      {isCheckboxMode && (
        <span className="sidebar__photos-zoom" onClick={handleOpenPhotoViewModal}>
          <svg className="icon__zoomin" width="20px" height="20px">
            <use xlinkHref="#zoomin" />
          </svg>
        </span>
      )}
    </div>
  )
}

export default SidebarPhoto
