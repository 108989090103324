import { colord } from 'colord'

type ReducedSizes = number[]

const MAX_SIZE = 200
const MIN_SIZE = 150

function getReducesSizes(width: number, height: number): ReducedSizes {
  let reducedWidth: number
  let reducedHeight: number

  if (width > height) {
    reducedWidth = MAX_SIZE
    reducedHeight = MIN_SIZE
  } else if (height > width) {
    reducedHeight = MAX_SIZE
    reducedWidth = MIN_SIZE
  } else {
    reducedWidth = MIN_SIZE
    reducedHeight = MIN_SIZE
  }

  return [reducedWidth, reducedHeight]
}

export const getContext = (width: number, height: number) => {
  const canvas = document.createElement('canvas')
  canvas.setAttribute('width', width.toString())
  canvas.setAttribute('height', height.toString())

  return canvas.getContext('2d')
}

export const getImageData = (src: string): Promise<Uint8ClampedArray> => {
  const img = new Image()

  return new Promise((resolve, reject) => {
    img.onload = function () {
      const [width, height] = getReducesSizes(img.width, img.height)

      const context = getContext(width, height)
      if (context) {
        context.drawImage(img, 0, 0, width, height)

        const { data } = context.getImageData(0, 0, width, height)
        resolve(data)
      }
    }

    const errorHandler = () => reject(new Error('An error occurred attempting to load image'))

    img.onerror = errorHandler
    img.onabort = errorHandler
    img.src = src
  })
}

export const convertToHsla = (color: string) => {
  const { h: hue, s: saturation, l: lightness, a: alpha } = colord(color).toHsl()

  return {
    hue,
    saturation,
    lightness,
    alpha,
    authored: color
  }
}

export const convertToHex = (color: string) => colord(color).toHex()

export const convertToHslaString = (color: string) => colord(color).toHslString()
