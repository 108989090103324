type DrawImageCropParams = Array<number>

export function getDrawImageCropParams(image: HTMLImageElement): DrawImageCropParams {
  const { width, height } = image

  const maxValue = Math.max(width, height)
  const minValue = Math.min(width, height)

  let coordinateCropX = 0
  let coordinateCropY = 0
  let imageCropWidth = 0
  let imageCropHeight = 0

  if (width > height) {
    coordinateCropX = (maxValue - minValue) / 2
    imageCropWidth = minValue - coordinateCropX
    imageCropHeight = height
  } else if (height > width) {
    coordinateCropY = (maxValue - minValue) / 2
    imageCropHeight = minValue - coordinateCropY
    imageCropWidth = width
  } else {
    imageCropHeight = height
    imageCropWidth = width
  }

  return [coordinateCropX, coordinateCropY, imageCropWidth, imageCropHeight]
}
