import { Image } from '@models/share'

export function drawFilledRect(
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  color = '#D6D6D6'
) {
  context.beginPath()
  context.rect(x, y, width, height)
  context.fillStyle = color
  context.fill()
}

export function createPreviewRectsWithoutImage(
  context: CanvasRenderingContext2D,
  previewSize: { width: number; height: number },
  amountPreviewCardsX: number,
  amountPreviewCardsY: number,
  step: number,
  defaultOffset: { x: number; y: number }
) {
  const { width, height } = previewSize
  const { x, y } = defaultOffset

  for (let iterationsY = 0; iterationsY < amountPreviewCardsY; iterationsY++) {
    const offsetY = calculateOffset(iterationsY, height, step, y)
    for (let iterationsX = 0; iterationsX < amountPreviewCardsX; iterationsX++) {
      const offsetX = calculateOffset(iterationsX, width, step, x)
      drawFilledRect(context, offsetX, offsetY, width, height)
    }
  }
}

export function createRoundRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius = 10
) {
  const fillStyle = '#F9F7FC'

  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  ctx.lineTo(x + width - radius, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
  ctx.lineTo(x + width, y + height - radius)
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
  ctx.lineTo(x + radius, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
  ctx.lineTo(x, y + radius)
  ctx.quadraticCurveTo(x, y, x + radius, y)
  ctx.closePath()

  ctx.fillStyle = fillStyle
  ctx.fill()
}

export function calculateOffset(currentIndex: number, size: number, step: number, defaultOffset: number): number {
  if (currentIndex === 0) {
    return defaultOffset
  }

  return size * currentIndex + defaultOffset + step * currentIndex
}

export function makeChunkedArray<T>(arr: T[], chunkSize = 5): T[][] {
  const chunkedArray: T[][] = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    chunkedArray.push(chunk)
  }
  return chunkedArray
}

export function makePreviews<T>(chunkedArray: T[][], size: number): T[] {
  const chunkedArrayPerSize: T[] = []
  for (let verticalIteration = 0; verticalIteration < size; verticalIteration++) {
    for (let horizontalIteration = 0; horizontalIteration < size; horizontalIteration++) {
      chunkedArrayPerSize.push(chunkedArray[verticalIteration][horizontalIteration])
    }
  }

  return chunkedArrayPerSize
}

export function getProductImagesMap(arrayImages: Image[]) {
  const productImagesMap = {} as Record<number, string>
  arrayImages.forEach((image, index) => {
    if (image.previewUrl != null) {
      productImagesMap[index] = image.previewUrl
    }
  })

  return productImagesMap
}
