import cls from 'classnames'
import { useStore } from 'effector-react'
import { $productImages, $productSize } from '@models/product'
import GridCell from '@components/GridCell/GridCell'
// eslint-disable-next-line import/named
import { NewIndexGetter } from '@dnd-kit/sortable'
import { useMemo, useState } from 'react'
import { makeChunkedArray, makePreviews } from '../../utils/product-previews/utils'

interface Props {
  top: number
  left: number
  width: number
  height: number
  isDragStart: boolean
  getNewIndex: NewIndexGetter
  editorPhotoId: string | null
  onGridImageClick: (id?: string) => void
  onShowFrameSizesClick: () => void
}

// const props: Partial<SortableProps> = {
//   adjustScale: true,
//   Container: (props: any) => <div {...props} />,
//   strategy: rectSortingStrategy,
//   wrapperStyle: () => ({
//     width: 140,
//     height: 140,
//   }),
// }

function Grid({
  top,
  left,
  width,
  height,
  getNewIndex,
  isDragStart,
  editorPhotoId,
  onGridImageClick,
  onShowFrameSizesClick
}: Props) {
  const [dropCounter, setDropCounter] = useState(0)
  const productImages = useStore($productImages)
  const productSize = useStore($productSize)

  const displayedImages = useMemo(() => {
    return makePreviews(makeChunkedArray(productImages), productSize)
  }, [productImages, productSize])

  /* eslint-disable react-perf/jsx-no-new-function-as-prop */
  return (
    <div className={cls(`content-grid-wrapper-${productSize}`)}>
      <div
        className="content__inner"
        onDragEnter={() => {
          setDropCounter(dropCounter + 1)
        }}
        onDragLeave={e => {
          // console.log()
          // if (e.target.classList.has('grid-photos-b__item_dropzone')) {
          // setIsDropHere(false)
          setDropCounter(dropCounter - 1)
          // }
        }}
      >
        <div className="content__actions">
          <div className="content__actions-item">
            <div className="content__actions-button" onClick={onShowFrameSizesClick}>
              <svg className="icon__grid" width="24px" height="24px">
                <use xlinkHref="#grid"></use>
              </svg>
            </div>
          </div>
        </div>
        <div
          className={cls(`grid-photos-b grid-photos-b_small frame-size-${productSize}`, {
            'grid-photos-b_blue': isDragStart || dropCounter > 0
          })}
        >
          {displayedImages.map((image, index) => (
            <GridCell
              key={image.id}
              image={image}
              index={index}
              size={162}
              editorPhotoId={editorPhotoId}
              left={0}
              top={0}
              onClick={onGridImageClick}
              getNewIndex={getNewIndex}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

// function Grid({ top, left, width, height }: Props) {
//   const [editorIndex, setEditorIndex] = useState<string | null>(null)

//   const productImages = useStore($productImages)

//   const productSize = useStore($productSize)

//   const padding = width / 12
//   const cellsGap = width / 60
//   const size = (width - cellsGap * productSize.w - padding * 2) / productSize.w

//   return (
//     <div className="content__inner">
//       <div className="content__inner-grid js--toggle-grid">
//         <svg className="icon__grid" width="24px" height="24px">
//           <use xlinkHref="#grid"></use>
//         </svg>
//       </div>

//       <div className="grid-photos-b">
//         {[...Array(productSize.h)].map((_, rowIndex) => {
//           const t = padding + rowIndex * size + rowIndex * cellsGap
//           return (
//             <>
//               {[...Array(productSize.h)].map((_, cellIndex) => {
//                 const l = padding + cellIndex * size + cellIndex * cellsGap
//                 const gridItemIndex = `${rowIndex}_${cellIndex}`
//                 return (
//                   <GridCell
//                     key={gridItemIndex}
//                     index={(rowIndex + 1) * (cellIndex + 1)}
//                     image={productImages.get(rowIndex * cellIndex) || null}
//                     size={size}
//                     left={l}
//                     top={t}
//                     showEditor={editorIndex === gridItemIndex}
//                     // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
//                     onClick={() => {
//                       if (productImages.has(rowIndex * cellIndex)) {
//                         if (editorIndex === gridItemIndex) {
//                           setEditorIndex(null)
//                         } else {
//                           setEditorIndex(gridItemIndex)
//                         }
//                       }
//                     }}
//                   />
//                 )
//               })}
//             </>
//           )
//         })}
//       </div>
//     </div>
//   )
// }

export default Grid
