interface Props {
  url: string
  onClose: () => void
}

function PhotoViewModal({ url, onClose }: Props) {
  if (!url) {
    return null
  }

  return (
    <div className="content__viewer">
      <div className="content__viewer-grid">
        <button className="content__viewer-close" onClick={onClose}>
          <svg className="icon__close" width="14px" height="14px">
            <use xlinkHref="#close"></use>
          </svg>
        </button>

        <div className="content__viewer-block">
          <div className="content__viewer-pic">
            <img src={url} alt="" />
          </div>
          <div className="content__viewer-note">
            Uploaded: <strong>06/10/2022</strong>&nbsp;&nbsp;&nbsp;Created: <strong>01/21/2022</strong>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PhotoViewModal
