import InstagramGray from '@assets/img/content/insta_gray.svg'
import InstagramLogo from '@assets/img/content/insta_logo.svg'
import SidebarPhoto from '@components/SidebarPhoto/SidebarPhoto'
import {
  $sidebarImages,
  $sidebarSelectedImages,
  $sortedSidebarImagesByColor,
  addFiles as addFilesToSidebar,
  addToFrameSidebarSelectedImages,
  deleteSidebarSelectedImages,
  selectSidebarImage
} from '@models/sidebar'
import { Image } from '@models/share'
import { useStore } from 'effector-react'
import { useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { $productImages } from '@models/product'
import SidebarCheckboxNodal from '@components/SidebarCheckboxModal/SidebarCheckboxModal'

interface Props {
  onOpenPhotoViewModal: (url: string) => void
}

function Sidebar({ onOpenPhotoViewModal }: Props) {
  const sortedImages = useStore($sortedSidebarImagesByColor)
  const galleryImages = useStore($sidebarImages)
  const selectedImages = useStore($sidebarSelectedImages)
  const isCheckboxMode = useMemo(() => selectedImages.length > 0, [selectedImages])

  // @todo: move to model
  const productImages = useStore($productImages)

  const [droppable, setDroppable] = useState(false)

  const { getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    accept: {
      'image/*': []
    },
    onDragOver: () => {
      setDroppable(true)
    },
    onDragLeave: () => {
      setDroppable(false)
    },
    onDrop: acceptedFiles => {
      addFilesToSidebar(acceptedFiles)
      setDroppable(false)
    }
  })

  function handleDeleteSelectedImages() {
    deleteSidebarSelectedImages(selectedImages)
  }

  function handleAddToFrame() {
    addToFrameSidebarSelectedImages(selectedImages)
  }

  function handleCancelCheckboxMode() {
    selectSidebarImage(null)
  }

  return (
    <aside className="sidebar" {...getRootProps()}>
      <input {...getInputProps()} />

      {droppable && (
        <div className="drag-b drag-b_blue">
          <div className="drag-b__icon">
            <svg className="icon__upload-here" width="57px" height="57px">
              <use xlinkHref="#upload-here" />
            </svg>
          </div>
        </div>
      )}

      <div className="sidebar__include">
        <div className="sidebar__include-close" />

        <div className="sidebar__tabs">
          <button className="sidebar__tabs-item sidebar__tabs-item_current">Imported</button>
          <button className="sidebar__tabs-item">Instagram</button>
        </div>

        <div className="sidebar__inner sidebar__inner_show" id="imported">
          <div className="sidebar__action">
            <button className="button button_light-green" onClick={open}>
              <svg className="icon__upload" width="36px" height="36px">
                <use xlinkHref="#upload" />
              </svg>
              Upload photos
            </button>
          </div>

          <div className="sidebar__block">
            {sortedImages.length > 0 ? (
              <div className="sidebar__scroll">
                <div className="sidebar__photos">
                  {sortedImages.map((f: Image, index: number) => (
                    <SidebarPhoto
                      key={index}
                      image={f}
                      countOfUsing={productImages.filter(pi => pi.originalId === f.originalId).length}
                      onOpenPhotoViewModal={onOpenPhotoViewModal}
                      isCheckboxMode={isCheckboxMode}
                      isActive={!!selectedImages.find(image => image.id === f.id)}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <>
                <div className="empty-b empty-b_desktop">
                  <div className="empty-b__icon">
                    <svg className="icon__upload-here" width="57px" height="57px">
                      <use xlinkHref="#upload-here" />
                    </svg>
                  </div>
                  <div className="empty-b__text">Drag your photos here from your desktop to upload</div>
                  <div className="empty-b__or">
                    <span>or use</span>
                  </div>
                  <div className="empty-b__button">
                    <a href="#" className="button button_light-yellow">
                      <img src={InstagramLogo} alt="" />
                      Instagram
                    </a>
                  </div>
                </div>
                <div className="empty-b empty-b_mobile">
                  <div className="empty-b__icon">
                    <svg className="icon__upload-here" width="57px" height="57px">
                      <use xlinkHref="#upload-here" />
                    </svg>
                  </div>
                  <div className="empty-b__text">Upload your photos or connect Instagram account</div>
                  <div className="empty-b__button">
                    <a href="#" className="button button_green">
                      <svg className="icon__upload" width="36px" height="36px">
                        <use xlinkHref="#upload" />
                      </svg>
                      Upload photos
                    </a>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="sidebar__inner" id="inst">
          <div className="sidebar__action">
            <a href="#" className="button button_light-yellow">
              <img src={InstagramLogo} alt="" />
              Connect with Instagram
            </a>
          </div>

          <div className="sidebar__block">
            <div className="empty-b empty-b_desktop">
              <div className="empty-b__icon">
                <img src={InstagramGray} alt="" />
              </div>
              <div className="empty-b__text">Your most beautiful photos are on your Instagram account.</div>
            </div>
            <div className="empty-b empty-b_mobile">
              <div className="empty-b__icon">
                <img src={InstagramGray} alt="" />
              </div>
              <div className="empty-b__text">Your most beautiful photos are on your Instagram account.</div>
              <div className="empty-b__button">
                <a href="#" className="button button_light-yellow">
                  <img src={InstagramLogo} alt="" />
                  Connect with Instagram
                </a>
              </div>
            </div>
          </div>
        </div>
        {isCheckboxMode && (
          <SidebarCheckboxNodal
            numberOfPhotos={selectedImages.length}
            handleCancelCheckboxMode={handleCancelCheckboxMode}
            handleAddToFrame={handleAddToFrame}
            handleDeleteSelectedImages={handleDeleteSelectedImages}
          />
        )}
      </div>
      <div className="sidebar__bg" />
    </aside>
  )
}

export default Sidebar
