import { createStore, createEvent, createEffect } from 'effector-logger'
import { Image } from '@models/share'
import { getAverageColor } from '../../shared/get-average-color'

export const addImages = createEvent<File[]>()
export const selectSidebarImageToDelete = createEvent<Image | null>()
export const deleteSidebarImage = createEvent<Image | null>()
export const selectSidebarImage = createEvent<Image | null>()
export const deleteSidebarSelectedImages = createEvent<Image[] | null>()
export const addToFrameSidebarSelectedImages = createEvent<Image[] | null>()

export const addFiles = createEvent<File[]>()

export const addImage = createEvent<Image>()

export const $sidebarImages = createStore<Image[]>([])
export const $sortedSidebarImagesByColor = createStore<Image[]>([])
export const $sidebarImageToDelete = createStore<Image | null>(null)
export const $sidebarSelectedImages = createStore<Image[]>([])

export const reindexFx = createEffect((images: Image[]) => {
  const m = new Map<string, number>()
  images.forEach(image => {
    if (image.previewUrl) {
      const count = m.get(image.originalId)
      if (count) {
        m.set(image.originalId, count + 1)
      } else {
        m.set(image.originalId, 1)
      }
    }
  })

  return m
})

export const addAverageColorFx = createEffect(async (images: Image[]) => {
  for (const image of images) {
    const { previewUrl } = image

    if (previewUrl) {
      image.averageColor = await getAverageColor(previewUrl)
    }
  }

  return images
})
