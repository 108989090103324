export const layoutSizes = {
  square: {
    layoutWidth: 220,
    layoutHeight: 220
  },
  portrait_52: {
    layoutWidth: 220,
    layoutHeight: 656
  },
  landscape_52: {
    layoutWidth: 656,
    layoutHeight: 220
  }
}
