import { Image } from '@models/share'

interface Props {
  top?: number
  left?: number
  width?: number
  height?: number
  image?: Image
}

function GridCellPhoto({ top, left, width, height, image }: Props) {
  if (!image) {
    return null
  }

  return (
    <div className="grid-photos-b__pic">
      <img src={image.previewUrl} alt="" />
    </div>
  )
}

export default GridCellPhoto
