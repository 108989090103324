// @todo to think on extends File

interface AverageColorHsla {
  hue: number
  saturation: number
  lightness: number
  alpha: number
  authored: string
}

export interface Image {
  id: string
  originalId: string
  srcUrl?: string
  previewUrl?: string
  averageColor?: AverageColorHsla
}

export function uuid() {
  let dt = new Date().getTime()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export function fileToImage(file: File): Image {
  const u = URL.createObjectURL(file)
  const parts = u.split('/')
  const id = parts[parts.length - 1]
  return Object.assign(file, {
    id,
    originalId: id,
    srcUrl: u,
    previewUrl: u
  })
}
