export const cardSizes = {
  square: {
    cardWidth: 188,
    cardHeight: 188
  },
  portrait_52: {
    cardWidth: 44,
    cardHeight: 44
  },
  landscape_52: {
    cardWidth: 44,
    cardHeight: 44
  }
}
