import {
  addImage,
  swapTwoImages,
  deleteImage,
  $productImages,
  $productSize,
  setProductSize,
  defaultProductImages,
  addFile
} from '.'
import { arraySwap } from '@dnd-kit/sortable'
import { uuid } from '@models/share'
import { addToFrameSidebarSelectedImages as addToFrame } from '@models/sidebar'

$productImages.on(addImage, (state, payload) => {
  const images = [...state]
  images[payload.cell] = { ...payload.image }
  return images
})

$productImages.on(swapTwoImages, (state, payload) => {
  const [index1, index2] = payload
  return arraySwap(state, index1, index2)
})

$productImages.on(deleteImage, (state, payload) => {
  const id = uuid()
  state[payload] = {
    id,
    originalId: id
  }
  return state
})

$productImages.on(addToFrame, (state, selectedImages) => {
  if (selectedImages) {
    let imageCounter = 0
    let i = 0

    while (imageCounter < selectedImages.length) {
      if (!state[i].previewUrl) {
        state[i] = { ...selectedImages[imageCounter] }
        imageCounter++
      }
      i++
    }
  }
  return [...state]
})

$productSize.on(setProductSize, (state, size) => {
  return size
})

// fetchUsersFx.use(async () => fetch('/users'))

// addGalleryImageFx.use(async user =>
//   fetch('/users', {
//     method: 'POST',
//     body: JSON.stringify(user),
//   }),
// )

// $usersMap.on(fetchUsersFx.doneData, (_, users) => users)

// forward({
//   from: addUserFx.doneData,
//   to: fetchUsersFx,
// })
