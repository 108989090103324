import classNames from 'classnames'
import { useMemo } from 'react'
import ProductPreview from '../ProductPreview/ProductPreview'
import ProductPreviewItem from '@components/ProductPreview/ProductPreviewItem'
import { getProductImagesMap, makeChunkedArray, makePreviews } from '../../utils/product-previews/utils'
import { Image } from '@models/share'
import { useStore } from 'effector-react'
import { $productSize } from '@models/product'

interface Props {
  showFrameSizes: boolean
  handleHideFrameSizes: () => void
  productImages: Image[]
}

interface ProductImagesMap {
  [key: number]: Record<number, string>
}

const frameSizes = [
  { w: 1, h: 1 },
  { w: 2, h: 2 },
  { w: 3, h: 3 },
  { w: 4, h: 4 },
  { w: 5, h: 5 }
]

function LayoutSwitcher({ showFrameSizes, handleHideFrameSizes, productImages }: Props) {
  const productSize = useStore($productSize)

  const memoizedProductImagesMap = useMemo(() => {
    const chunkedArray: Image[][] = makeChunkedArray(productImages)
    const productImagesMap = {} as ProductImagesMap

    for (let i = 0; i < frameSizes.length; i++) {
      productImagesMap[i] = getProductImagesMap(makePreviews(chunkedArray, i + 1))
    }
    return productImagesMap
  }, [productImages])

  return (
    <div className={classNames('content__layout content__layout_grid', { content__layout_show: showFrameSizes })}>
      <div className="content__layout-head">
        <div className="content__layout-title">Layouts</div>
        <div className="content__layout-close" onClick={handleHideFrameSizes}>
          <svg className="icon__close" width="14px" height="14px">
            <use xlinkHref="#close" />
          </svg>
        </div>
      </div>

      <div className="content__layout-grid">
        <div className="content__layout-list">
          {frameSizes.map((s, i) => (
            <ProductPreviewItem key={i} currentSize={s.w} isSelectedItem={productSize === s.w}>
              <ProductPreview height={s.h} width={s.w} urls={memoizedProductImagesMap[i]} />
            </ProductPreviewItem>
          ))}
        </div>
      </div>
    </div>
  )
}

export default LayoutSwitcher
