import { ReactComponent as Logo } from '../../assets/img/content/logo.svg'

function Header() {
  return (
    <header className="header-b">
      <div className="header-b__grid">
        <div className="header-b__column">
          <div className="header-b__burger">
            <i></i>
            <i></i>
            <i></i>
          </div>

          <div className="header-b__logo">
            <a href="/">
              <Logo />
            </a>
          </div>

          <div className="header-b__frame">
            <div className="header-b__frame-label">Legacy Frame with mat • 36 x 106 cm</div>
          </div>
        </div>

        <div className="header-b__actions">
          <a href="#" className="button button_blue button_preview">
            <svg className="icon__preview" width="22px" height="16px">
              <use xlinkHref="#preview"></use>
            </svg>
            Preview
          </a>
          <a href="#" className="button button_save">
            <svg className="icon__save" width="20px" height="20px">
              <use xlinkHref="#save"></use>
            </svg>
            <span>Save</span>
          </a>
          <a href="#" className="button button_green button_icon-left">
            £102.40
            <svg className="icon__cart" width="22px" height="22px">
              <use xlinkHref="#cart"></use>
            </svg>
          </a>
        </div>
      </div>

      <div className="header-b__bottom">
        <div className="header-b__frame">
          <div className="header-b__frame-label">Legacy Frame with mat • 36 x 106 cm</div>
          <div className="header-b__frame-select">
            <div className="header-b__frame-value">
              <span>Portrait</span>
              <svg className="icon__toggle" width="12px" height="6px">
                <use xlinkHref="#toggle"></use>
              </svg>
            </div>
            <div className="header-b__frame-drop">
              <div className="header-b__frame-item">Album</div>
            </div>
          </div>
        </div>
      </div>

      <div className="header-b__toggle">
        <div className="header-b__menu">
          <div className="header-b__menu-column">
            <div className="header-b__menu-title">Frames</div>
            <div className="header-b__menu-list">
              <a href="#" className="header-b__menu-item">
                Moments Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Classic Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Montage Gallery Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Gallery prints
              </a>
              <a href="#" className="header-b__menu-item">
                Acryllic prints
              </a>
            </div>
          </div>
          <div className="header-b__menu-column">
            <div className="header-b__menu-title">Prints</div>
            <div className="header-b__menu-list">
              <a href="#" className="header-b__menu-item">
                Moments Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Classic Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Montage Gallery Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Gallery prints
              </a>
              <a href="#" className="header-b__menu-item">
                Acryllic prints
              </a>
              <a href="#" className="header-b__menu-item">
                Acryllic prints
              </a>
              <a href="#" className="header-b__menu-item">
                Acryllic prints
              </a>
            </div>
          </div>
          <div className="header-b__menu-column">
            <div className="header-b__menu-title">Photo Books</div>
            <div className="header-b__menu-list">
              <a href="#" className="header-b__menu-item">
                Moments Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Classic Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Montage Gallery Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Gallery prints
              </a>
            </div>
          </div>
          <div className="header-b__menu-column">
            <div className="header-b__menu-title">Wedding</div>
            <div className="header-b__menu-list">
              <a href="#" className="header-b__menu-item">
                Moments Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Classic Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Montage Gallery Frame
              </a>
            </div>
          </div>
          <div className="header-b__menu-column">
            <div className="header-b__menu-title">Discover</div>
            <div className="header-b__menu-list">
              <a href="#" className="header-b__menu-item">
                Moments Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Classic Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Montage Gallery Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Gallery prints
              </a>
            </div>
          </div>
          <div className="header-b__menu-column">
            <div className="header-b__menu-title">Gifts</div>
            <div className="header-b__menu-list">
              <a href="#" className="header-b__menu-item">
                Moments Frame
              </a>
              <a href="#" className="header-b__menu-item">
                Classic Frame
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
