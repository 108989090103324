function Controls() {
  return (
    <div className="content__filter">
      <div className="content__filter-item">
        <div className="content__zoom">
          <div className="content__zoom-button">
            <svg className="icon__zoomin" width="20px" height="20px">
              <use xlinkHref="#zoomin"></use>
            </svg>
          </div>
          <div className="content__zoom-value">100%</div>
          <div className="content__zoom-button">
            <svg className="icon__zoomout" width="20px" height="20px">
              <use xlinkHref="#zoomout"></use>
            </svg>
          </div>
        </div>
      </div>

      <div className="content__filter-item">
        <div className="content__paging">
          <div className="content__paging-button">
            <svg className="icon__prev" width="20px" height="15px">
              <use xlinkHref="#prev"></use>
            </svg>
          </div>
          <div className="content__paging-button">
            <svg className="icon__next" width="20px" height="15px">
              <use xlinkHref="#next"></use>
            </svg>
          </div>
        </div>
      </div>

      <div className="content__filter-item content__filter-item_button">
        <a href="#" className="button button_green js--toggle-sidebar">
          <svg className="icon__upload" width="36px" height="36px">
            <use xlinkHref="#upload"></use>
          </svg>
          Upload photos
        </a>
      </div>
    </div>
  )
}

export default Controls
