import {
  addFiles,
  addImage,
  deleteSidebarImage,
  $sidebarImageToDelete,
  reindexFx,
  selectSidebarImageToDelete,
  $sidebarImages,
  $sidebarSelectedImages,
  selectSidebarImage,
  addAverageColorFx,
  $sortedSidebarImagesByColor,
  deleteSidebarSelectedImages,
  addToFrameSidebarSelectedImages
} from '.'

import { sample } from 'effector-logger'
import { $productImages, addImage as addProductImage, deleteImage as deleteProductImage } from '@models/product'
import { fileToImage, uuid } from '@models/share'

$sidebarImages.on(addFiles, (state, files) => {
  const images = files.map(file => fileToImage(file))
  return state.concat(images)
})

$sidebarImageToDelete.on(selectSidebarImageToDelete, (state, image) => {
  return image
})

$sidebarImages.on(deleteSidebarImage, (state, pickedImage) => {
  if (pickedImage) {
    return state.filter(image => image.id !== pickedImage.id)
  }
})

$sidebarImages.on(addImage, (state, image) => {
  return state.concat([image])
})

$sidebarImages.on(reindexFx.done, (state, payload: any) => {
  const m = payload.result
  state.forEach(image => {
    const count = m.get(image.originalId)
    if (count && count > 0) {
      image.id = uuid()
    }
  })

  return state
})

$sortedSidebarImagesByColor.on(addAverageColorFx.doneData, (_, images) => {
  return images.sort((a, b) => {
    if (a.averageColor && b.averageColor) {
      const colorA = a.averageColor
      const colorB = b.averageColor

      // Move grey-ish values to the back
      if ((colorA.saturation === 0 || colorB.saturation === 0) && colorA.saturation !== colorB.saturation) {
        return colorB.saturation - colorA.saturation
      }

      // Sort by hue (lowest first)
      if (colorA.hue !== colorB.hue) {
        return colorA.hue - colorB.hue
      }

      // Sort by saturation (highest first)
      if (colorA.saturation !== colorB.saturation) {
        return colorA.saturation - colorB.saturation
      }

      // Comparing gray values, light before dark
      if (colorA.saturation === 0 && colorB.saturation === 0) {
        if (colorA.lightness !== colorB.lightness) {
          return colorB.lightness - colorA.lightness
        }
      }

      // Sort by transparency, least transparent first
      if (colorA.alpha === colorB.alpha) {
        return colorA.authored.toLowerCase().localeCompare(colorB.authored.toLowerCase())
      }

      return colorB.alpha - colorA.alpha
    } else {
      return 0
    }
  })
})

$sidebarImages.on(deleteSidebarSelectedImages, (state, selectedImages) => {
  if (selectedImages) {
    return state.filter(image => !selectedImages.find(img => image.id === img.id))
  } else {
    return state
  }
})

$sidebarSelectedImages.on(selectSidebarImage, (state, img) => {
  if (img) {
    const currentImageInState = state.find(image => image.id === img.id)

    if (currentImageInState) {
      return state.filter(stateImage => stateImage.id !== img.id)
    } else state.push(img)

    return [...state]
  } else {
    return []
  }
})

sample({
  clock: addProductImage,
  source: $productImages,
  fn: images => images,
  target: reindexFx
})

sample({
  clock: deleteProductImage,
  source: $productImages,
  fn: images => images,
  target: reindexFx
})

sample({
  source: $sidebarImages,
  fn: images => images,
  target: addAverageColorFx
})

sample({
  clock: addToFrameSidebarSelectedImages,
  source: $productImages,
  fn: images => images,
  target: reindexFx
})

sample({
  clock: deleteSidebarImage,
  source: $sidebarImageToDelete,
  fn: () => null,
  target: selectSidebarImageToDelete
})

sample({
  clock: deleteSidebarSelectedImages,
  source: $sidebarSelectedImages,
  fn: () => null,
  target: selectSidebarImage
})

sample({
  clock: addToFrameSidebarSelectedImages,
  source: $sidebarSelectedImages,
  fn: () => null,
  target: selectSidebarImage
})

// fetchUsersFx.use(async () => fetch('/users'))

// addGalleryImageFx.use(async user =>
//   fetch('/users', {
//     method: 'POST',
//     body: JSON.stringify(user),
//   }),
// )

// $usersMap.on(fetchUsersFx.doneData, (_, users) => users)

// forward({
//   from: addUserFx.doneData,
//   to: fetchUsersFx,
// })
