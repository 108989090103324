import { calculateOffset, drawFilledRect } from './utils'
import { getDrawImageCropParams } from './getDrawImageCropParams'
import { Urls } from '../../types/product-previews/product-preview-props'

interface PreviewSize {
  width: number
  height: number
}

interface DefaultOffset {
  x: number
  y: number
}

export function fillPreviewImages(
  ctx: CanvasRenderingContext2D,
  previewSize: PreviewSize,
  urls: Urls,
  countPreviewCardsX: number,
  countPreviewCardsY: number,
  step: number,
  defaultOffset: DefaultOffset
) {
  const { width, height } = previewSize
  const { x, y } = defaultOffset

  let counterImages = 0

  let countIterationsY = 0
  while (countIterationsY < countPreviewCardsY) {
    const offsetY = calculateOffset(countIterationsY, height, step, y)
    for (let countIterationsX = 0; countIterationsX < countPreviewCardsX; countIterationsX++) {
      const offsetX = calculateOffset(countIterationsX, width, step, x)
      if (urls[counterImages]) {
        const img = new Image()
        img.src = urls[counterImages]
        if (img) {
          const [coordinateCropX, coordinateCropY, imageCropWidth, imageCropHeight] = getDrawImageCropParams(img)
          img.onload = function () {
            ctx.drawImage(
              img,
              coordinateCropX,
              coordinateCropY,
              imageCropWidth,
              imageCropHeight,
              offsetX,
              offsetY,
              width,
              height
            )
          }
        }
      } else {
        drawFilledRect(ctx, offsetX, offsetY, width, height)
      }
      counterImages++
    }
    countIterationsY++
  }
}
