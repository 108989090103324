/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { useCallback, useState } from 'react'
import Grid from './components/Grid/Grid'
import Header from './components/Header/Header'
import Sidebar from './components/Sidebar/Sidebar'
import SvgIcons from './components/SvgIcons/SviIcons'
import LayoutSwitcher from './components/LayoutSwitcher/LayoutSwitcher'

import {
  DndContext,
  closestCenter,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  KeyboardSensor,
  PointerSensor
} from '@dnd-kit/core'
import { useStore } from 'effector-react'
import { $sidebarImages } from '@models/sidebar'
import SidebarPhoto from '@components/SidebarPhoto/SidebarPhoto'
import { $productImages, addImage as addProductImage, swapTwoImages as swapTwoProductImages } from '@models/product'
import { SortableContext, sortableKeyboardCoordinates, rectSwappingStrategy, arraySwap } from '@dnd-kit/sortable'
import { createPortal } from 'react-dom'
import PhotoViewModal from '@components/PhotoViewModal/PhotoViewModal'
import cls from 'classnames'
import Controls from '@components/Controls/Controls'
import PhotoDeleteModal from '@components/PhotoDeleteModal/PhotoDeleteModal'

function App() {
  const [top, setTop] = useState(100)
  const [left, setLeft] = useState(100)
  const [width, setWidth] = useState(800)
  const [height, setHeight] = useState(800)
  const [showFrameSizes, setShowFrameSizes] = useState(false)

  function handleShowFrameSizes() {
    setShowFrameSizes(!showFrameSizes)
  }

  // useEffect(() => {
  //   // loadImage('/img/avocado.jpg').then((image: HTMLImageElement) => {
  //   // const a = new Array(cellsCount * cellsCount);
  //   // setImages(a.fill(null));
  //   // });

  //   return

  //   setWidth(document.getElementById('product-background')!.clientWidth)
  //   setHeight(document.getElementById('product-background')!.clientWidth)
  // }, [productSize.h, productSize.w])

  // const documentHeight = document.body.clientHeight
  // const top = Math.min(width / 2, documentHeight - 100);
  // console.log(top, width / 2, documentHeight - 100, top);

  // useEffect(() => {
  //   function handleResize() {
  //     return

  //     setWidth(document.getElementById('product-background')!.clientWidth)
  //     setHeight(document.getElementById('product-background')!.clientWidth)
  //     const productContainer = document.getElementById('product-container')
  //     if (productContainer) {
  //       // Что я тут хотел сделать???
  //       // console.log(
  //       //   'document.body.clientWidth',
  //       //   document.body.clientWidth,
  //       //   'productContainer.clientWidth',
  //       //   productContainer.clientWidth,
  //       //   (productContainer.clientWidth - 140) / 2
  //       // );
  //       // console.log(
  //       //   'document.body.clientHeight',
  //       //   document.body.clientHeight,
  //       //   'productContainer.clientHeight',
  //       //   productContainer.clientHeight,
  //       //   (productContainer.clientHeight - 140) / 2
  //       // );
  //     }
  //   }

  //   window.addEventListener('resize', handleResize)
  // })

  function handleHideFrameSizes() {
    setShowFrameSizes(false)
  }

  const sidebarImages = useStore($sidebarImages)
  const productImages = useStore($productImages)

  const [activeId, setActiveId] = useState(null)
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('')
  function handleOpenPhotoViewModal(url: string) {
    setSelectedImageUrl(url)
  }
  function handleClosePhotoViewModal() {
    setSelectedImageUrl('')
  }

  const [editorPhotoId, setEditorPhotoId] = useState<string | null>(null)
  function handleGridImageClick(id?: string) {
    setEditorPhotoId(id || null)
  }

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 1 } }),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  function handleDragStart(event: any) {
    setActiveId(event.active.data.current.originalId)
  }

  function handleDragEnd(event: any) {
    setActiveId(null)
    const { active, over } = event
    if (active && over && over.data && over.data.current) {
      const { originalId: activeId } = active.data.current
      const { index: overIndex } = over.data.current
      const image = sidebarImages.find(image => image.originalId === activeId)
      if (image) {
        const { type, index: activeIndex } = active.data.current
        if (type === 'sidebar') {
          addProductImage({ cell: overIndex, image })
        } else {
          swapTwoProductImages([activeIndex, overIndex])
        }
      }
    }
    // console.log(
    //   'over && over.data.current.accepts.includes(active.data.current.type)',
    //   over && over.data.current.accepts.includes(active.data.current.type)
    // )
    // if (active && over && active.id !== over.id) {
    //   // setItems((items) => {
    //   //   const oldIndex = items.indexOf(active.id)
    //   //   const newIndex = items.indexOf(over.id)
    //   //   return arrayMove(items, oldIndex, newIndex)
    //   // })
    // }
  }

  function handleDragCancel() {
    setActiveId(null)
  }

  return (
    <>
      <SvgIcons />
      <div className="wrapper">
        <Header />
        <div className="main">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            <Sidebar onOpenPhotoViewModal={handleOpenPhotoViewModal} />

            <section
              className={cls('content', {
                content_hidden: selectedImageUrl
              })}
            >
              <PhotoViewModal url={selectedImageUrl} onClose={handleClosePhotoViewModal} />
              <SortableContext items={productImages.map(pi => pi.id)} strategy={rectSwappingStrategy}>
                <Grid
                  top={top}
                  left={left}
                  width={width}
                  height={height}
                  isDragStart={!!activeId}
                  getNewIndex={({ id, items, activeIndex, overIndex }) =>
                    arraySwap(items, activeIndex, overIndex).indexOf(id)
                  }
                  editorPhotoId={editorPhotoId}
                  onGridImageClick={handleGridImageClick}
                  onShowFrameSizesClick={handleShowFrameSizes}
                />
              </SortableContext>
              <LayoutSwitcher
                showFrameSizes={showFrameSizes}
                handleHideFrameSizes={handleHideFrameSizes}
                productImages={productImages}
              />
            </section>

            {createPortal(
              <DragOverlay adjustScale={false}>
                {activeId && (
                  <SidebarPhoto image={sidebarImages.find(image => image.originalId === activeId)!} isDragging />
                )}
              </DragOverlay>,
              document.body
            )}
          </DndContext>
          <Controls />
        </div>
        <PhotoDeleteModal />
      </div>
    </>
  )
}

export default App
